<template>
    <div>
        
        <div v-if="isInitializing" class="loading">
            <unc-loader></unc-loader>
        </div>
    
        <div v-else-if="showForm" class="animated fadeIn">
            <div class="row">
                <div class="col-md-12 col-sm-12 col-xs-12">
                    <admin-permissions-form :dataParent="dataForChild" @eventToParent="eventFromChild"></admin-permissions-form>
                </div>
            </div>
        </div>
    
        <div v-else class="row">
            <div class="col-md-12 col-sm-12 col-xs-12">
                <div class="card">
                    <div class="card-header" :class="this.person.state.code === 'A' ? 'bgm-green' : 'bgm-gray'">
                        <h2>{{ listTitle }}</h2>
                    </div>
    
                     <div class="card-body">
                        <div v-if="adminManagedOrgUnits.length === 0" class="jumbotron text-center">
                            <h3>No se han encontrado dependencias vinculadas.</h3>
                        </div>
                        <div v-else class="my-listview">
                            <div class="row my-listview-item hidden-xs">
                                <div class="col-md-5 col-sm-6 col-xs-12"><b>Dependencia</b></div>
                                <div class="col-md-2 col-sm-2 col-xs-12 text-center"><b>Membresía</b></div>
                                <div class="col-md-4 col-sm-2 col-xs-12 text-center"><b>Permisos</b></div>
                                <div class="col-md-2 col-sm-2 col-xs-12"></div>
                            </div>
    
                            <div class="row my-listview-item" v-for="ou in adminManagedOrgUnits" :key="ou.orgUnit._id">
                                <div class="col-md-5 col-sm-6 col-xs-12">
                                    <h1>{{ ou.orgUnit.name }}</h1><small>{{ ou.orgUnit.shortName }} - Código: {{ ou.orgUnit.code }}</small>
                                </div>
                                <div class="col-md-2 col-sm-2 col-xs-12 text-center">
                                    <h1>{{ou.typeMemberName}}</h1>
                                </div>
                                <div class="col-md-4 col-sm-2 col-xs-12 text-center">
                                    <div v-if="ou.permissionsNames.length !== 0">
                                        <h1 v-for="permission in ou.permissionsNames">
                                            {{ permission }}
                                        </h1>
                                    </div>
                                    <div v-else>-</div>
                                </div>
    
                                <div class="col-md-1 col-sm-2 col-xs-12">
                                    <ul class="my-actions">
                                        <li><a href="" @click.prevent="editItem(ou.orgUnit, item._id, ou.permissions)" title="Editar Permisos"><i class="zmdi zmdi-edit"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div> 
                </div>
                <div v-if="isLoading" class="loading">
                    <unc-loader></unc-loader>
                </div>
            </div>
        </div>
    </div>
</template>
    
<script>
    import { HTTPFront, HttpYULI } from '../../share.js';
    import { mapGetters, mapActions } from "vuex";
    import adminPermissionsForm from './admin.permissions.form.vue';

    export default {
        data() {
            return {
                adminManagedOrgUnits: [],
                dataForChild: {},
                isInitializing: true,
                isLoading: false,
                item: '',
                listTitle: 'PERMISOS POR DEPENDENCIA',
                permissionsList: [],
                showForm: false,
            }
        },
        computed: {
            ...mapGetters(["person"]),
        },        
        props: [
            'dataParent'
        ],
        components: {
            adminPermissionsForm,
        },
        async mounted() {
            this.item = this.person;
            await this.getData();
        },
        methods: {
            ...mapActions(['setPermissions', 'setPermissionsView']),
            async getData() {
                this.isLoading = true;
                this.isInitializing = true;
                let personId = this.item._id;
                await this.getPermissionList();
                const response = await HTTPFront.get("/orgUnitsByUser/" + personId);
                this.adminManagedOrgUnits = response.data.filter(ou => ou.typeMember === 2).map(ou => {
                    const permissionsNames = this.getPermissionsName(ou.permissions);
                    return { ...ou, typeMemberName: 'Administrador', permissionsNames };
                });
                this.isLoading = false;
                this.isInitializing = false;
            },
            async getPermissionList () {
                const response = await HttpYULI.get('/v2/permissions');
                if (response.status === 200) {
                    this.setPermissions(response.data);
                    this.permissionsList = response.data;
                }
            },
            getPermissionsName(codes) {
                const permissionsNames = [];
                codes.forEach(code => {
                    const permission = this.permissionsList.find(p => p.code === code);
                    permissionsNames.push(permission.name);
                })
                return permissionsNames;
            },
            editItem(orgUnit, idPersona, permissions) {
                this.dataForChild = {
                    orgUnit: orgUnit,
                    idPersona: idPersona,
                    permissions: permissions,
                }
                this.showForm = true;
            },
            eventFromChild(action = '', params = null){
                switch (action) {
                    case 'canceled':
                        this.showForm = false;
                    break;

                    case 'saved':
                        this.showForm = false;
                        if (params && params?.lostPermission) {
                            this.setPermissionsView(false);
                            return this.$router.push('/');
                        }
                        this.getData();
                    break;
                }
            }
        }
    }
</script>

<style lang="scss">
    
</style>
<template>
    <div>
        <div v-if="isInitializing" class="loading">
            <unc-loader></unc-loader>
        </div>
        <div v-show="!isInitializing" class="card animated fadeIn">
            <div class="card-header ch-alt">
                <h2>{{ cardTitle }}</h2>
                <ul class="actions">
                    <li>
                        <a href @click.prevent="$emit('eventToParent','canceled')">
                            <i class="zmdi zmdi-close"></i>
                        </a>
                    </li>
                </ul>
            </div>
            <div v-if="loadingAction" class="text-center p-l-0 p-r-0 loading card-body card-padding">
                <unc-loader></unc-loader>
            </div>
            <div v-else-if="optionsIdentityTypes.length === 0 && !editComponent" class="jumbotron text-center">
                <p>⚠️</p>
                <p style="font-weight: 400;">Este usuario ya tiene todos los tipos de documentos argentinos vinculados. 
                    Por el momento no se pueden vincular más tipos de documentos.
                </p>
                <button @click="$emit('eventToParent','canceled')" type="button" class="btn btn-primary btn-sm m-t-10 waves-effect">Volver</button>
            </div>
            <div v-else class="card-body card-padding">
                <form @submit.prevent="validateBeforeSubmit" :name="editComponent ? 'editDocumentForm' : 'addDocummentForm'">
                    <div style="margin-bottom: 3rem;">
                        <div class="fg-line">
                            <li style="list-style: none;">
                                <label>País:</label>
                                <br>
                                <span> Argentina </span>
                            </li>
                        </div>
                    </div>
                    <div v-if="!editComponent" class="form-group" :class="{'has-error': errors.has('identityTypeId')}">
                        <div class="fg-line">
                            <label>Tipo de Documento:</label>
                            <div class="select">
                                <select v-model="item.identityTypeId" id="identityTypeId" name="identityTypeSelect" v-validate="'required'" class="form-control">
                                    <option value="">[Seleccioná una opción]</option>
                                    <option v-for="opt of optionsIdentityTypes" :key="opt.id" :value="opt.id">{{ opt.short_name + ' - ' + opt.name }}</option>
                                </select>
                            </div>
                        </div>
                        <small v-if="errors.has('identityTypeId')" class="help-block">{{ errors.first('identityTypeId') }}</small>
                    </div>
                    <div v-else style="margin-bottom: 3rem;">
                        <div class="fg-line">
                            <li style="list-style: none;">
                                <label>Tipo de Documento:</label>
                                <br>
                                <span> {{ item.identityTypeName }} </span>
                            </li>
                        </div>
                    </div>
                    <div class="form-group" :class="{'has-error': errors.has('identityNumberInput')}">
                        <div class="fg-line">
                            <label>Número de Documento</label>
                            <input v-model="item.identityNumber" id="identityNumber" name="identityNumberInput" v-validate="selectedIdentityValidation" type="text" class="form-control" placeholder="Ingresá el número de documento...">
                        </div>
                        <small v-if="errors.has('identityNumberInput')" class="help-block">{{ errors.first('identityNumberInput') }}</small>
                    </div>
                    <div class="row justify-content-center">
                        <div class="col-sm-5 img-padding-top" >
                            <div class="form-group">
                                <label for="supportingDocument">Constancia de Documento </label> 
                                <br>
                                <span class="">
                                <input type="file" ref="file" accept="application/pdf" @change="onFilePicked($event,'supportingDocument')" required name="supportingDocumentFile" id="supportingDocument" v-validate="'required'">
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="text-right">
                        <div>
                            <button @click="$emit('eventToParent','canceled')" type="button" class="btn btn-link btn-sm m-t-10 waves-effect" name="goBackToConfigurationButton">Atrás</button>
                            <button type="submit" class="btn btn-primary btn-sm m-t-10 waves-effect" :name="editComponent ? 'editDocumentButton' : 'addDocumentButton'">{{ buttonText }}</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>
    
<script>
import { HttpYULI, HttpGEO, swal } from "../../share.js";
import { mapGetters } from "vuex";

export default {
    data() {
        return {
            allIdentityTypes: [],
            buttonText: 'AGREGAR',
            cardTitle: 'AGREGAR DOCUMENTO',
            documentMap: [],
            editComponent: false,
            files: '',
            fileName: '',
            operation: "",
            isInitializing: false,
            item: {
                identityTypeId: "",
                identityTypeName: "",
                identityNumber: "",
                selectedCountry: "",
            },
            loadingAction: false,
            optionsIdentityTypes: [],
        };
    },
    computed: {
        ...mapGetters([
            "person",
            "optionsCountries",
            "identityTypes",
            "fileSizeLimit",
            "currentUser",
            "userHeader"
        ]),
        selectedIdentityValidation: function() {
            const identityTypeCode = this.optionsIdentityTypes.find(el => el.id === this.item.identityTypeId)?.code;
            if(identityTypeCode === "ARG_CUIL"){
                return 'required|min:11|max:13|cuilFormat|cuilNumber';
            }
            if(identityTypeCode === "ARG_DNI" || identityTypeCode === "ARG_DNIT"){
                return 'required|between:1000000,99999999|min:7|max:8|dni';
            }
            return 'required';
        },
    },
    props: ["dataParent"],
    async mounted() {
        this.isInitializing = true;
        const allowedIdentityTypes = ['ARG_CUIL', 'ARG_DNI', 'ARG_DNIT'];
        this.allIdentityTypes = this.identityTypes;
        this.optionsIdentityTypes = this.allIdentityTypes.filter(identity => allowedIdentityTypes.includes(identity.code));
        if (this.optionsIdentityTypes.length === 0) {
            const argIdentityTypes = await this.getArgIdentityTypes();
            this.optionsIdentityTypes = argIdentityTypes.data.results.filter(i => allowedIdentityTypes.includes(i.code));
        } else {
            this.optionsIdentityTypes = this.optionsIdentityTypes.filter(identity => !this.person.identities.some(i => i.identityType === identity.code))
        }
        this.item.selectedCountry = this.optionsCountries.find(country => country.name_iso === 'Argentina');
        // Setting up component
        this.operation = this.dataParent.operation;
        this.editComponent = this.operation === 'editItem';
        if(this.editComponent) {
            this.cardTitle = 'EDITAR DOCUMENTO';
            this.buttonText = 'Confirmar';
            this.item.identityTypeId = this.dataParent.parentSelected.geobjectIdentity.id;
            this.optionsIdentityTypes = this.allIdentityTypes.filter(identity => identity.code === this.dataParent.parentSelected.geobjectIdentity.code);
            this.item.identityTypeName = this.dataParent.parentSelected.geobjectIdentity.short_name + ' - ' + this.dataParent.parentSelected.geobjectIdentity.name;
            this.item.identityNumber = this.dataParent.parentSelected.identityNumber;
        }
        this.isInitializing = false;
    },
    methods: {
        getArgIdentityTypes() {
            return HttpGEO.get(`countries/ARG/identity_types`);
        },
        validateBeforeSubmit(e) {
            this.$validator
                .validateAll()
                .then(() => {
                    if (!this.errors.any()) {
                        this.save();
                    } else {
                        console.log("No Validated: ", JSON.stringify(this.errors.items));
                    }
                })
                .catch(() => {
                    console.log("Error Validated!");
                });
        },
        async save() {
            this.loadingAction = true;
            const identityType = this.optionsIdentityTypes.find(identity => identity.id === this.item.identityTypeId);
            const requestBody = {
                identityNumber: this.item.identityNumber,
            };
            const formData = new FormData();
            formData.append('form', JSON.stringify(requestBody));
            Object.keys(this.documentMap).forEach(key => {
                this.documentMap[key].forEach(file=>{
                    formData.append('file', file, file.name);
                })
            });
            try {
                const response = await HttpYULI.put(`/v2/people/${this.person._id}/identities/${identityType.code}`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        [this.userHeader]: this.currentUser.id,
                    }
                });
                if (response.status === 200) {
                    const updatedPerson = {...this.person};
                    const index = this.person.identities.findIndex(identity => identity.identityType === identityType.code);

                    if (index !== -1) {
                        updatedPerson.identities[index].identityNumber = this.item.identityNumber;
                    } else {
                        updatedPerson.identities.push({
                            identityType: identityType.code,
                            identityNumber: this.item.identityNumber,
                        });
                    }
                    await this.$store.commit("setPerson", updatedPerson);
                    await swal.fire({
                        title: 'Éxito',
                        text: 'Los datos fueron guardados correctamente.',
                        icon: 'success',
                        showConfirmButton: true,
                    });
                    this.$emit('eventToParent','canceled');
                }
            } catch (error) {
                let status = error?.response && error?.response?.status || error?.response?.data?.code;
                let message = error?.response && error?.response?.data?.message || 'Servicio temporalmente no disponible';

                switch (status) {
                    case 401:
                        swal.fire({ title: 'Acceso denegado', text: 'No posee permisos para acceder a este servicio.', icon: 'warning' });
                        break;
                    case 409:
                        swal.fire({ title: 'Oops!', text: 'Parece que este tipo y número de documento ya está vinculado a otro Usuario UNC. Comunicate con Mesa de Ayuda mediante un ticket para que revisemos la situación.', icon: 'warning' });
                        break;
                    case 422:
                        swal.fire({ title: 'Oops!', text: 'El número de documento inresado no es válido.', icon: 'warning' });
                        break;
                    case 500:
                        await swal.fire({ title: 'Algo salió mal', text: message, icon: 'warning' });
                        break;
                    default:
                        await swal.fire({ title: 'Documento no modificado', text: message, icon: 'warning' });
                        break;
                }
            } finally {
                this.documentMap = {};
                this.loadingAction = false;
            }
        },
        onFilePicked (e,key) {
            const files = e.target.files;
            if(files.length) {
                Array.from(files).forEach(file => {
                    if(file.size > this.fileSizeLimit){
                        swal.fire({title: 'Oops', text: 'El archivo elegido es muy grande', icon: 'warning' })
                        e.target.value = '';
                        return;
                    } else {
                        const fr = new FileReader ()
                        fr.readAsDataURL(file)
                        fr.addEventListener('load', () => {
                            if(!this.documentMap[key]){
                                this.documentMap[key] = [];
                            }
                            this.documentMap[key].push(file);
                        })
                    }
                })
            } 
        },
    }
};
</script>

<style lang="scss">
</style>
<template>
    <div>
        <div class="row animated fadeIn">
            <div class="col-md-12 col-sm-12 col-xs-12">
                <div class="card">
                    <div class="card-header bgm-green ch-alt">
                        <h2>HISTORIAL DE CAMBIOS</h2>
                    </div>
                    <div class="card-body">
                        <div class="row" style="display: flex; justify-content: center; align-items: center; margin-top: 2rem;">
                            <div class="col-sm-6">
                                <div class="form-group" >
                                    <div class="fg-line">
                                        <label>Filtro:</label>
                                        <div class="select">
                                            <select
                                                @change="onChange()"
                                                id="historyType"
                                                name="historyType"
                                                class="form-control"
                                                v-validate="'required'"
                                                v-model="selectedHistory"
                                            >                                            
                                                <option v-for="op of item.changeTypes" :key="op.value" :value="op">{{ op.name }}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-if='isLoading' class="loading" style="padding-bottom: 2rem;">
                            <unc-loader></unc-loader>
                        </div>
                        <div v-else-if="item.changeHistory.length > 0" class="my-listview">
                            <div class="row my-listview-item th hidden-xs" style="padding-left: 0;">
                                <div class="text-center col-md-2 col-sm-2 col-xs-12">Fecha</div>
                                <div class="text-center col-md-5 col-sm-6 col-xs-12">Descripción</div>
                                <div class="text-center col-md-2 col-sm-2 col-xs-12">
                                    <div style="display: flex; align-items: flex-start; justify-content: center;">
                                        Estado
                                        <a
                                        style="font-size: 20px;margin-left: 5px; margin-top: -0.5rem"
                                        data-toggle="popover"
                                        data-placement="right"
                                        tabindex="0"
                                        data-original-title="Más información"
                                        >
                                            <i class="zmdi zmdi-pin-help"></i>
                                        </a>
                                        <br>
                                    </div>
                                </div>
                                <div class="text-center col-md-3 col-sm-2 col-xs-12">Autor</div>
                            </div>
                            <div class="row my-listview-item" style='padding-left: 0; padding-right: 0;' v-for="item in item.changeHistory" :key="item.timestamp">
                                <div class="col-md-3 col-sm-2 col-xs-12">
                                    <p>{{ formatDate(item.date) }}</p>
                                </div>
                                <div v-html="item.description" class="col-md-4 col-sm-6 col-xs-12">
                                </div>
                                <div class="text-center col-md-2 col-sm-2 col-xs-12 hidden-xs">
                                    <p> {{ item.state }}</p>
                                </div>
                                <div class="text-left col-md-2 col-sm-2 col-xs-12 hidden-sm hidden-md hidden-lg hidden-xl">
                                    <p> 
                                        <strong>Estado:</strong> {{ item.state }}
                                    </p>
                                </div>
                                <div class="text-center col-md-3 col-sm-2 hidden-xs">
                                    <p>
                                        {{ item.author }}
                                    </p>
                                </div>
                                <div class="col-md-3 col-sm-3 col-xs-12 hidden-sm hidden-md hidden-lg hidden-xl">
                                    <p>
                                        <strong>Autor:</strong> {{ item.author }}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div v-else class="jumbotron text-center">
                            <h3>No se han encontrado cambios de este tipo.</h3>
                        </div>
                    </div>
                </div>
            </div>           
        </div>
    </div>
</template>
    
<script>
    import { mapState } from 'vuex';
    import { HTTPFront, HttpYULI, swal } from '../../share';
    import moment from 'moment';
    
    export default {
        data() {
            return {
                adminsMap: {},
                item: {
                    changeTypes: [],
                    changeHistory: [],
                },
                isLoading: false,
                selectedHistory: {
                    value: 'recoveryEmail',
                    name: 'Cambio de correo de recuperación',
                },
                userData: '',
            }
        },
        computed: {
            ... mapState([
                'person'
            ]),
        },
        created() {

        },
        async mounted() {
            /*INFO: Los tipos de historiales se dejan HC por ahora */
            //Popover
            this.isLoading = true;
            this.userData = await this.getUserData();
            await this.fetchHistoryData('recoveryEmail');
            this.item.changeTypes = [
                { name: 'Cambio de contraseña', value: 'password' },
                { name: 'Cambio de correo de recuperación', value: 'recoveryEmail' },
            ];
            this.isLoading = false;
            $(function () {
                $('[data-toggle="popover"]').popover({
                    content: '<p><span class="badge bgm-green">Confirmado:</span><span> El cambio fue solicitado y se completó en la fecha indicada</span><br><span class="badge bgm-orange">Pendiente:</span><span> El cambio fue solicitado en la fecha indicada pero no fue completado</span><br> <span class="badge bgm-red">Expirado:</span><span> El cambio fue solicitado, no se completó y expiró al solicitar un nuevo cambio</span><br></p>',
                    html : true,
                })
            });
        },
        methods: {
            async getUserData() {
                try {
                    const response = await HttpYULI.get(`/v1/persons/${this.person._id}/users`);
                    return response.data;
                } catch (err) {
                    this.handleGenericError(err)
                } finally {
                    this.isLoading = false;
                }
            },
            formatDate(timestamp) {
                return moment(timestamp).format('DD/MM/YYYY, HH:mm') + 'h';
            },
            async fetchHistoryData(type) {
                this.isLoading = true;
                try {
                    const history = type === 'recoveryEmail'
                        ? this.person?.recoveryEmailHistory
                        : this.userData[0]?.passwordChangeHistory || [];
                    this.item.changeHistory = history ? await this.formatHistory(history, type) : [];
                } catch (err) {
                    this.handleGenericError(err);
                } finally {
                    this.isLoading = false;
                }
            },
            onChange() {
                this.fetchHistoryData(this.selectedHistory?.value);
            },
            async formatHistory(history, type) {
                /* INFO: funcion que formatea los distintos historiales para mostrarlos en una tabla genérica */
                /* Se deja como alernativa la idea de generar un componente distinto para cada historial */
                /* Para poder manejar el formateo de cada historial por separado */
                if(type === 'recoveryEmail') {
                    await this.getAdmins(history.map(item => item.changedBy));
                    return history?.map((item, index) => {
                        return {
                            date: item.confirmedAt ?? item.timestamp,
                            description: `
                            <p>
                                <strong>Nuevo:</strong> ${item.newEmail}
                            </p>`,
                            state: item.confirmedAt !== null ? 'Confirmado' : index === 0 ? 'Pendiente' : 'Expirado',
                            author: this.adminsMap[item.changedBy],
                        };
                    });
                } else if(type === 'password') {
                    return history.map(item => {
                        return {
                            date: item.timestamp,
                            description: '<p>Se realizó un cambio de contraseña</p>',
                            state: 'Confirmado',
                            author: 'Usuario',
                        };
                    });
                }
            },
            async getAdmins(adminIds) {
                try {
                    const adminsToSearch = Array.from(new Set(adminIds)).filter(personId => !this.adminsMap[personId]);
                    if(adminsToSearch.length === 0) {
                        return;
                    }
                    const response = await HTTPFront.post('adminData', { adminIds: adminsToSearch });
                    response.data.forEach(user => {
                        if (!this.adminsMap[user.personId]) {
                            this.adminsMap[user.personId] = user.userName;
                        }
                    });
                } catch (err) {
                    this.handleGenericError(err);
                }
            },
            handleGenericError(error) {
                let status = error?.response && error?.response?.data.code || error?.response?.status;
                let message = error?.response && error?.response?.data.message || 'Servicio temporalmente no disponible';
                switch (status) {
                    case 401:
                        swal.fire({title: 'Acceso denegado', text: 'No posee permisos para acceder a este servicio.', type: 'warning' });
                        break;
                    case 500:
                        swal.fire({title: 'Algo salió mal', text: message, type: 'warning' });
                        break;
                    default:
                        swal.fire({title: 'Algo salió mal', text: message, type: 'warning' });
                        break;
                }
            },
        }
    }
</script>
    
<style lang="scss">
.popover{
        max-width:600px;
        width: 200px;
    }
</style>
<template>
  <div>
    <div v-if="isInitializing" class="loading">
      <unc-loader></unc-loader>
    </div>

    <div v-show="!isInitializing" class="card animated fadeIn">
      <div class="card-header ch-alt">
        <h2>MODIFICAR MEMBRESÍA</h2>

        <ul class="actions">
          <li>
            <a href @click.prevent="$emit('eventToParent','canceled')">
              <i class="zmdi zmdi-close"></i>
            </a>
          </li>
        </ul>
      </div>

      <div v-if="loadingAction" class="text-center p-l-0 p-r-0 loading card-body card-padding">
        <unc-loader></unc-loader>
      </div>
      <div v-else class="card-body card-padding">
        <form @submit.prevent="validateBeforeSubmit">
          <div class="form-group">
            <div class="fg-line">
                <label>Dependencia</label><br>
                <h1 class="dependency">{{ orgUnitSelected.name }}</h1>
            </div>
          </div>

          <div class="form-group" :class="{'has-error': errors.has('typeMember')}">
            <div class="fg-line">
              <label>Membresía</label>
              <div class="select">
                <select v-model="item.typeMember" id="typeMember" name="typeMember" v-validate="'required'" class="form-control">
                  <option v-for="item of optionsMembresia" :value="item.typeMember">{{ item.name }}</option>
                </select>
              </div>
            </div>
            <small v-if="errors.has('typeMember')" class="help-block">El campo es requerido</small>
            <span>La membresía "Administrador" permite a la persona acceder al sistema de Gestión de Usuario UNC.</span><br>
            <span>La membresía "Miembro" solo vincula a la persona a una Dependencia sin que esta pueda acceder al sistema de Gestión de Usuario UNC.</span>
          </div>

          <div class="form-group" :class="{'has-error': errors.has('uncProfile')}">
            <div class="fg-line">
              <label>Roles</label>
            </div>
            <div v-for="profile of checkboxPerfiles" :key="profile.code" class="checkbox m-b-30">
              <label>
                <input name="uncProfile" type="checkbox" v-model="item.uncProfiles" :value="profile.code" v-validate="'required'"/>
                <i class="input-helper"></i>
                {{ profile.name }}
              </label>
            </div>
            <small v-if="errors.has('uncProfile')" class="help-block">Es requerido seleccionar al menos un rol</small>
          </div>

          <div class="text-right">
            <button
            @click="$emit('eventToParent','canceled')"
            type="button"
            class="btn btn-link btn-sm m-t-10 waves-effect"
            >Atrás</button>
            <button type="submit" class="btn btn-primary btn-sm m-t-10 waves-effect">Modificar</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { HTTPFront, HttpYULI, swal } from "../../share.js";
import axios from "axios";
import { mapGetters } from "vuex";
import membershipTypes from '../../../utils/membership_types';

export default {
  data() {
    return {
      idSelected: 0,
      operation: "",
      isInitializing: false,
      item: {
        orgunit_id: "",
        typeMember: "",
        uncProfiles: []
      },
      personSearch: {},
      optionsOrgUnits: [],
      optionsMembresia: membershipTypes,
      checkboxPerfiles: [],
      orgUnitsTree: [],
      adminId: '',
      orgUnitSelected: {},
      loadingAction: false,
    };
  },
  computed: {
    ...mapGetters(["person", "uncProfiles", "currentUser", "userHeader"])
  },
  components: {
  },
  props: ["dataParent"],
  mounted() {
    this.checkboxPerfiles = this.uncProfiles;
    this.getData();
  },
  methods: {
    getData() {
        let self = this;
        this.personId = this.dataParent.idPersona;
        this.orgUnitSelected = this.dataParent.orgUnit;
        this.item.typeMember = this.dataParent.typeMember;
        this.item.uncProfiles = this.dataParent.uncProfiles;
    },
    validateBeforeSubmit(e) {
       this.$validator
        .validateAll()
        .then(() => {
          if (!this.errors.any()) {
            this.save();
          } else {
            console.log("No Validated: ", JSON.stringify(this.errors.items));
          }
        })
        .catch(() => {
          console.log("Error Validated!");
        });
    },
    save() {
      this.loadingAction = true;

      let self = this;

      let addPerson = {
        personId: self.personId,
        orgUnitId: self.orgUnitSelected._id,
        typeMember: self.item.typeMember,
        uncProfiles: self.item.uncProfiles
      };

      HTTPFront.post("/modifyPersonInOrgUnit", addPerson, {
          headers: {
            [this.userHeader]: this.currentUser.id,
          },
        })
        .then(respuesta => {
          if (respuesta.status === 200) {
          swal.fire({ title: 'Éxito', html: `${this.person.names} ${this.person.lastNames} se ha modificado satisfactoriamente`, icon: "success", useRejections: false })
          .then(() => {
            let self = this;
            self.$emit('eventToParent','saved');
          })
          } else {
            swal.fire({ title: "Error: " + respuesta.status, text: respuesta.statusText, icon: "error", useRejections: false });
          }
        })
        .catch(error => {
            let status = error.response.status;
            let message = error.response && error.response.data || 'Servicio temporalmente no disponible';

              switch (status) {
                  case 401:
                      swal.fire({title: 'Acceso denegado', text: 'No posee permisos para acceder a este servicio.', icon: 'warning', useRejections: false });
                      break;
                  case 409:
                      swal.fire({title: 'Datos no guardados', text: 'Ya existe una persona creada con este número de documento.', icon: 'warning', useRejections: false });
                      break;
                  case 500:
                      swal.fire({title: 'Algo salió mal', text: 'Servicio no disponible, por favor intente más tarde.', icon: 'warning', useRejections: false });
                      break;
                  default:
                      swal.fire({title: 'Datos no guardados', text: 'Servicio no disponible, por favor intente más tarde.', icon: 'warning', useRejections: false });
                      break;
              }
        })
        .finally(() => {
          this.isLoading = false;
          this.isInitializing = false;
          this.loadingAction = false;
        });

    }
  }
};
</script>

<style lang="scss">
</style>